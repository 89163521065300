#authorization-dictum-warranty{
  .btns-authorize{

    button{
      width: 100%;
      font-family: Roboto-Bold;

    }
  }
  #rubros-dictum{
    .title-header{
      cursor: pointer;
    }
    .list-group-item{
      border: none;

    }
  }

  #create-engagments{
    font-family: Roboto-Bold, Arial, Serif;
    font-size: 17px;
    .label{
      padding-top: 14px;
      color: #0061F5;
    }
  }
  @media (min-width: 600px) {
    .dropdown-more-options {

    }
  }

  .authorized-amount{
    font-family: Roboto-Bold, Arial, serif;
  }

  #modal-comfirm{
    font-family: Roboto-Regular, Arial;
  }

  @media (max-width:768px) {
    .btn-authorization{
      margin-bottom: 10px;
    }
  }

  #modal-rechazo{
    .multi-select{
      margin-top: 10px;
    }
  }
}