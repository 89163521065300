$colorPrimaryYellow:#FFD000;
$colorPrimaryBlue:#1A1446;

$colorPrimaryDark: #00001e;
$colorLight: #443b70;


$colorDanger: #FFF4F5;
$colorWarning: #FFEEDC;
$colorSuccess: #E1F2E6;

$colorAcent: #ffbf00;

$primaryText: rgb(24,21,68);


