#creditbureau {
    padding-left: 8%;
    padding-right: 8%;

    .cards-percentage-bc {
        .card-title {
            font-family: Roboto-Regular, serif;
            border-bottom: 4px solid #1a1446;
            padding-bottom: 15px;
            display: flex;
        }

        #progressbar {
            align-items: center;
            align-content: center;
        }
    }

    #search {
        display: flex;
        align-items: center;
        align-content: flex-start !important;

        padding: 3px;
        width: 300px;
        height: 40px;
        border-radius: 10px;

        #input-search {
            background: transparent;
            width: 450px;
        }
    }



    .Modal-fecha{
        align-items: center !important;

        .btn-archivo{
            margin-left: 55px;
        }
    }

    .custom-input-file {
        background-color: #06748c;
        color: #fff;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        margin: 0 auto 0;
        min-height: 10px;
        overflow: hidden;
        padding: 10px;
        position: relative;
        text-align: center;
        width: 400px;
        border-radius: 10px;
    }
    input[type="file"] {
        display: none;
        cursor: pointer;
    }

    .Modal-pdf {
        width: 100%;
        height: 100%;
        border: none;
        .iframe-pdf {
            display: block;
            /* iframes are inline by default */
            background: rgb(255, 255, 255);
            border: none;
            /* Reset default border */

            width: 98%;
            height: 100vh;
            /* Viewport-relative units 600px*/

            padding-left: 2%;
            padding-right: 2%;
        }

        #btn-fiado-busqueda {
            margin-left: 70%;
            margin-top: 7%;
        }
    }
    @media only screen and (min-width: 600px) {
        .lm-Modal.lm-Modal--large .lm-Modal-wrapper {
            max-width: 1200px;
            overflow: scroll;
        }
    }
    .custom-input-file .input-file {
        border: 10000px solid transparent;
        cursor: pointer;
        font-size: 10px;
        margin: 0;
        opacity: 0;
        outline: 0 none;
        padding: 0;
        position: absolute;
        right: -1000px;
        top: -1000px;
        display: none;
    }

    .custom-file-upload {
        cursor: pointer;
    }

    

    #saveandContinue {
        margin-left: 55px;
    }

    #table-credit {
        .tb {
            width: 250px;
            align-items: center;
            position: relative;
            justify-content: center !important;
            align-content: center !important;
        }
    }
}
