@import "../../../assets/css/colors";
#warranties{
  @media only screen and (max-width: 768px) {
    #btn-search-principals{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  #create-warranty{
    font-family: Roboto-Regular;
    font-size: 19px;
    padding-top: 10px!important;
  }
  .waranty-container{
    padding-left: 8%;
    padding-right: 8%;

    .bg-danger{
      background-color: #D32F2F!important;
    }
    .bg-success{
      background-color: #008040!important;
    }
    .bg-warning{
      background-color: #FFD000!important;
    }
    .card{
      margin-bottom: 20px;
    }
  }
  .cards-percentage{
    .card-title{
      font-family: Roboto-Regular,serif;
      border-bottom: 4px solid #1A1446;
      padding-bottom: 15px;
    }
  }
  #card-warranties{
    margin-top: 25px;
  }
  .item-warranty{
    padding-top: 3%;
    padding-bottom: 3%;

    h4{
      font-family: Roboto-Ligth, serif;
    }
    .amount{
      font-family: Roboto-Regular, Arial;
      font-size: 22px;
    }
    Badge{
      margin-top: 15px;

    }
    .see-details{
      padding-top: 19px;
      a{
        color: #28A3AF!important
      }

    }
  }
  .status-fianza{
    font-family: Roboto-Bold, Arial;
  }

  .name-agent{
    padding-top: 25px;
    padding-right: 15px;
    text-align: right;
    width: 225px;
    font-family: Roboto-Bold,Arial, Serif;
  }
  .list-clients{
    max-height: 200px;
    overflow-y: auto;
  }

  .elementos{
    margin-bottom: 10px;
  }
  .estatus{
    padding-top: 8px;
    padding-left: 8px;
  }
  .icon-warranty .lm-Icon{
    cursor: pointer;
  }
  .label-lock{
    font-family: Roboto-Bold,Arial, Serif;
    text-align: center;

    font-size: 11px;
  }
}