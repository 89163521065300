.lmig-Popover-overlay {
  background: #99E5EA;
  color: #1A1446;
  width: 230%;

}

#demo-table {

  .btn-detail {
    white-space: pre;
  }

  .custom-color-no-arrow {
    color: #1A1446;
    background: #99E5EA;
  }

  .extras {
    text-align: center;
    white-space: pre;
  }

  .extrasE {
    text-align: left;
    white-space: pre;
  }

  .prod {
    margin-left: 25px;
    color: #D9E8FE;
  }

  .cond {
    margin-left: 40px;
    color: #D9E8FE;
  }

  .info {
    margin-left: 45px;
    color: red;

  }

  .motivo {
    margin-left: 20px;
  }

}


#modal3 {
  max-width: 800px;
  color: #343741 !important;
  position: fixed;
  margin: auto;

  h2 {
    font-size: large;
    color: #343741 !important;
  }

  .info {
    margin-left: 35%;
  }

  .texto {
    color: #343741 !important;
    font-size: large;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10%;
    margin-bottom: 6%;

  }

  .btn-cancel {
    margin-left: 33%;
  }

}


#modal4 {
  max-width: 800px;
  color: #343741 !important;
  position: fixed;
  margin: auto;

  h2 {
    font-size: large;
    color: #343741 !important;
  }

  .info {
    margin-left: 38%;
  }

  .texto {
    color: #343741 !important;
    font-size: large;
    margin-left: 5%;
    margin-right: 1%;
    margin-top: 10%;
    margin-bottom: 6%;

  }

  .btn-cancel {
    margin-left: 33%;
  }

}