#create-query {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  
    #header-create {
      padding-top: 20px;
      width: 100%;
      .container {
        #pipeline {
          padding-left: 35%;
        }
      }
  
      #formulario {
        padding-left: 0%;
        padding-right: 0;
        width: 100%;
  
        #form-query {
          padding-top: 3%;
          width: 100%;
        }
      }
      #cards {
        align-items: center !important;
        align-content: center !important ;
        height: 300px;
        cursor: pointer;
        padding: 0px 0;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.8);
        border: none;
        border-radius: 10px;
  
        #alert_up {
          padding-top: 3%;
          padding-left: 20%;
        }
        .btn-archivo{
          margin-left:155px ;
        }
  
        h3 {
          align-content: center;
          align-items: center;
        }
  
        .global-class-name {
          color: red;
        }
  
        p {
          color: rgb(149, 149, 149);
        }
  
        a {
          color: rgb(149, 149, 149);
        }
  
        #file-upload-input {
          position: absolute;
          display: flex;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          outline: none;
          opacity: 0;
          cursor: pointer;
        }
        #image-upload-wrap {
          position: absolute;
          align-content: center !important;
          align-items: center !important;
          height: 100%;
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
        }
        #image-upload-wrap:hover {
          background-color: transparent;
          border: 4px dashed transparent;
        }
        #text-information {
          margin-top: 30px;
          text-align: center;
        }
      }
      .Modal-pdf{
        width: 100%;
        height: 100%;
        border: none;
        .iframe-pdf {  
          display: block;       
          /* iframes are inline by default */  
          background: #000; 
          border: none;         
          /* Reset default border */  
             
          width: 98%;
          height:100vh;
          /* Viewport-relative units 600px*/  
         
          padding-left: 2%;  
          padding-right: 2%;}
    
        #btn-fiado-busqueda{
          margin-left: 70%;
          margin-top: 7%;
        }
      }
  
      #tabla {
        padding-left: 0%;
        padding-right: 5%;
      }
    }

    .lm-Modal .lm-Modal-wrapper .lm-Modal-title {
      margin: 0;
      text-align: R;
      color: #343741;
  }

  .Modal-fiados{
    width: 80%;
    height: 80%;
    padding-left:15%;

    #btn-fiado-busqueda{
      margin-left: 70%;
      margin-top: 7%;
    }
  }
  
    @media only screen and (min-width: 600px) {
      #form-query {
        padding-left: 2%;
        padding-right: 2%;
      }
    }

    .lm-Modal.lm-Modal--large .lm-Modal-wrapper {
      max-width: 1200px;
    
    }

    .autocomplete-wrapper-fiado {
      width: 100%;
      position: relative;
      display: inline-block;
      cursor: pointer;
    
    }
    
    .autocomplete-wrapper-fiado > div {
      width: 100%;
      cursor: pointer;
     
    }
    
    .autocomplete-wrapper-fiado input {
      border-radius: 3px;
      border: 1px solid  #919191;
      padding: 12px 15px;
      font-size: 18px;
      width: 100%;
      cursor: pointer;
      padding: 1em .375em .8125em;
    }
    
    
    .autocomplete-wrapper-fiado input:hover {
      border: 1px solid #28a3af;
      padding: 12px 15px;
      font-size: 18px;
      width: 100%;
      cursor: pointer;
      border-radius: 3px;
      padding: 1em .375em .8125em;
     
    }
    
    .autocomplete-wrapper-fiado input:focus {
      border-color: #28a3af;
      box-shadow: none;
      outline: none;
      cursor: pointer;
      border-radius: 3px;
    }
    
    .autocomplete-wrapper-fiado .dropdown {
      width: 100%;
      padding: 0;
      text-align: left;
      max-height: 280px;
      overflow: scroll;
      background-color: #ffffff;
      border: 1px solid  #28a3af;
      border-top: none;
      cursor: pointer;
      border-radius: 3px;
      position: relative;
      z-index: 3;
      font-size: 1rem;
      -webkit-transition: opacity .1s ease-out 0s;
      transition: opacity .1s ease-out 0s;
      border-radius: 3px;
      text-overflow: ellipsis;
    
      outline: none;
    
    }
    
    .autocomplete-wrapper-fiado .item  {
      display: block;
      cursor: pointer;
      font-size: 15px;
      color: #919191;
      padding: 1px;
      cursor: pointer;
      
    }
    
    .autocomplete-wrapper-fiado .item.selected-item {
      background-color: #28a3af;
      color: #FAFBFC;
      cursor: pointer;
    }
    
    .autocomplete-wrapper-fiado .item:hover {
      background-color:  #28a3af;
      color: #FAFBFC;
      cursor: pointer;
    }
  }
  