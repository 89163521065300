#linea-de-afianzamiento{
  #progress-monto-maxiomo-ac{
    background-color: #28A3AF!important;
  }
  #progress-monto-maxiomo-ev{
    background-color: #E6E6E6!important;
  }

  #create-line{
    margin-top: 20px;
    margin-bottom: 20px;
  }


}