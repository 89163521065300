@import "../../../assets/css/colors";
#warranty-history {
  padding-left: 6%;
  padding-right: 6%;
  .container-center {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
}
