@import "../../../assets/css/colors";
#warranty-review{
  padding-left: 6%;
  padding-right: 6%;
  .recommned-percent{
    font-family: Roboto-Ligth, Arial, Serif;
    font-size: 12px;
  }
  #check-list-doc{
    padding-top: 4px;
    padding-left: 13px;
    cursor: pointer;
  }
  textarea[disabled] {

    background-color: rgb(230,230,230	);
  }

  #comentarios-warranty-review{
    display: none;
  }
  .comments-warranty-review{
    cursor: pointer!important;
    color: #1A1446;
  }

  .badge-light {
    color: #ffffff;
    background-color:  #1A1446;
  }
  
}