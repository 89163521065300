@import "../../../assets/css/colors";
#count{
  //background-image: linear-gradient(8deg,white 36%,#ffd000 0%);

  .container-count{
   // margin-top: 5%;
    position: relative;
   // background-color: rgba(180, 180, 180, 0.55);
    padding: 20px 0;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media only screen and (max-width: 992px) {
    .container-count{
      margin-top: 8%;
    }

  }
  h3{
    color: $colorPrimaryBlue;
    font-family: Roboto-Regular;
    margin-bottom: 15px;
  }

  .ico-liberty{
    max-width: 240px;
    padding-top: 5%;
  }

  #remember-psw{
    font-family: Roboto-Regular;
  }
  .btn-login{
    min-width: 100%;
  }

  .btn-confirmacion{
    margin-bottom: 2%;
    margin-left: 4%;
  }

  #form-count{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }
  .input-group{

    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: auto!important;
    cursor: pointer;
    outline: none;
    border: 1px solid #E6E6E6;
    background-color: #E6E6E6;
    color: #343741!important;
    margin-bottom: 5px;
    margin-left: 3px;
    margin-right: 3px;
    .input-user-name{
      background-color: transparent!important;
      border: none!important;
    }
    @media only screen and (min-width: 1104px) {
      .col-md-2{
        border-right: 1px solid #C0BFC0;
        text-align: center;
      }

    }

  }
  .lm-Icon-MyAccount, .lm-Icon-LockClosed{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  #img-profile{
    margin-top: -55px!important;
    border-radius: 50%;
  }
  
  #img-count{
    margin-top: -55px!important;
    border-radius: 50%;
  }
  #ico-show-psw, #ico-hidden-psw{
    .img-fluid{
      margin-top: 15px;
    }

  }

}