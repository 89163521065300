#resume-garantias{
  .rol{
    span{
      font-family: Roboto-Ligth;
      font-size: 10px;
    }
  }
  .accions{
    min-width: 310px;
  }
  .cantidad{
    min-width: 170px;
  }
  .totalAfiazado{
    text-align: right;
    min-width: 195px;
  }

  .button-download-totalAfianzado{
    display: inline; /* the default for span */

  }
  #tabla-resumen{

  }
  @media only screen and (max-width: 767px) {
    #tabla-resumen{
      .columnas{
        .nombre, .cantidad, .rol{
          text-align: center!important;
        }
        .accions[data-header]:not([data-header=''])::before{
          display: none;
        }

      }
    }

  }
  @media only screen and (min-width: 768px) {
    #tabla-resumen{
      .columnas{
        .nombre{
          text-align: left!important;
        }
        .cantidad{
          text-align: right!important;
        }
        .rol{
          text-align: center!important;
        }


      }
    }
  }
}