#ventana-mantenimiento{
  background: #F5F5F5;
  padding-top: 0px;


  #search{
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #E6E6E6;
  }
  .list-fiados{
    padding-top: 25px;
  }
  .principal-selected{
    padding-top: 25px;
  }

  .mantenimiento-container{
    padding-left: 3%;
    padding-right: 3%;

    .bg-danger{
      background-color: #D32F2F!important;
    }
    .bg-success{
      background-color: #008040!important;
    }
    .bg-warning{
      background-color: #FFD000!important;
    }
    .card{
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}