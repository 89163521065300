.custom-table {
  margin-top: 15px;

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    &__table, &__loading-message {
      width: 99%;
      grid-row-start: 1;
      grid-column-start: 1;
    }

    &__loading-message {
      text-align: center;
      height: 100%;
      width: 100%;
      background-color: rgba(255,255,255,0.5);
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__custom-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
