#info-principal{

  .interview-text{
    .lm-LinkStandalone{
      font-family: Roboto-Medium;
      font-weight: normal;
    }
  }





  .status-expedient{
    margin-top: 5px;
  }

  .open-expedient{
    cursor: pointer;
  }

  #dropdown-basic-fiance{
    margin-bottom: 10px;
    display: inline!important;

  }

  .spinner-border {
    color: #06748c!important;
  }

  .list-obligors{
    max-height: 100px;
    overflow-y: auto;
  }
  @media only screen and (max-width: 992px) {
    .info-text{
      text-align: center!important;
    }

  }

  @media only screen and (min-width: 992px) {
    .info-warranty{

    }

  }
}

