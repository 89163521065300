#pipelineCE{
  


  @media only screen and  (max-width: 576px) {
    #hr{
      height:2px;
      width:100%;
      border-width:0;
      //background-color: green;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
       //bottom: -5px;
       margin-left: -1px;
       margin-top: 10px;
       margin-bottom: 20px;
      
    }

    .pipe{

      position: relative;
      z-index: 1;
      padding-bottom: 3%;
      margin-left: 25%;
      width: 50%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }

    #hrr{
      height:2px;
      width: 100%;
      border-width:0;
      //background-color: green;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -1px;
      margin-top: 10px;
      margin-bottom: 80px;
    }

    .pipeDetails{

      position: relative;
      z-index: 1;
      padding-bottom: 3%;
      margin-left: 25%;
      width: 50%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }
  } 

  @media only screen and (min-width: 576px) and (max-width: 768px) {
    #hr{
      height:2px;
      width: 100%;
      border-width:0;
      //background-color:red;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      //bottom: -5px;
      margin-left: -1px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .pipe{

      position: relative;
      z-index: 1;
      padding-bottom: 3%;
      margin-left: 25%;
      text-align: center;
      width: 50%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }

    #hrr{
      height:2px;
      width: 105%;
      border-width:0;
      //background-color:red;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -1px;
      margin-top: 10px;
      margin-bottom: 80px;
    }

    .pipeDetails{

      position: relative;
      z-index: 1;
      padding-bottom: 3%;
      margin-left: 25%;
      text-align: center;
      width: 50%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }
  } 

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    #hr{
      height:2px;
      width:150%;
      border-width:0;
      //background-color: blue;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: 50px;
    }

    .pipe{

      position: relative;
      z-index: 1;
      margin-left: -10px;
      width: 200%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }

    #hrr{
      height:2px;
      width: 135%;
      border-width:0;
      //background-color: blue;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -50px;
    }

    .pipeDetails{

      position: relative;
      z-index: 1;
      padding-bottom: 3%;
      margin-left: -120px;
      width: 200%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }
  } 


  @media only screen and (min-width: 992px) and (max-width: 1200px){
    #hr{
      height:2px;
      width: 140%;
      border-width:0;
      //background-color:purple;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: 80px;
    }

    .pipe{

      position: relative;
      z-index: 1;
      margin-left: -10px;
      width: 200%;
      
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }

    #hrr{
      height:2px;
      width: 130%;
      border-width:0;
      //background-color:purple;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -15px;
    }

    .pipeDetails{

      position: relative;
      z-index: 1;
      padding-bottom: 3%;
      margin-left: -120px;
      width: 200%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }
  } 

  @media only screen and (min-width: 1200px) {
    #hr{
      height:2px;
      width: 145%;
      border-width:0;
      //background-color: brown;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: 5px;
    }
    .pipe{

      position: relative;
      z-index: 1;
      margin-left: -100px;
      width: 200%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }

    #hrr{
      height:2px;
      width: 130%;
      border-width:0;
      //background-color: brown;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -120px;
    }   

    .pipeDetails{

      position: relative;
      z-index: 1;
      margin-left: -250px;
      width: 200%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }
  } 

  @media only screen and (min-width: 1200px)  and (min-width: 1500px)  {
    #hr{
      height:2px;
      width: 110%;
      border-width:0;
      //background-color: darkcyan;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -100px;
    }
    .pipe{

      position: relative;
      z-index: 1;
      margin-left: -200px;
      width: 150%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }

    #hrr{
      height:2px;
      width: 105%;
      border-width:0;
      //background-color: darkcyan;
      background-color:#E6E6E6;
      position: relative;
      z-index: -1;
      bottom: -42px;
      margin-left: -120px;
    }

    .pipeDetails{

      position: relative;
      z-index: 1;
      margin-left: -250px;
      width: 155%;
  
      .badge{
        font-family: Roboto-Regular,Arial,serif;
        font-size: 17px;
        width: 47px;
        height: 47px;
        padding-block: 14px;
        border: 1px solid #C0BFC0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
      .item-name{
        font-family: Roboto-Ligth,Arial,serif;
      }
    }
  } 


  

  } 


