#documento-garantia-mantenimiento{
  padding-bottom: 55px;
  .recommned-percent{
    font-family: Roboto-Ligth, Arial, Serif;
    font-size: 12px;
  }
  #check-list-doc{
    padding-top: 4px;
    padding-left: 13px;
    cursor: pointer;
  }
  textarea[disabled] {

    background-color: rgb(230,230,230	);

  }

  .expedient-files{



    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      cursor: pointer;
      display: inline-block;

    }

  }
}