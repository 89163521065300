@import "../../../assets/css/colors";
#garantias-mantenimiento {
  a{
    cursor: pointer;
  }
  .desc-gar{
    max-width: 140px;
  }
  .vigency-gar{
    min-width: 115px;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .caret-off::before {
    display: none;
  }

  .caret-off::after {
    display: none;
  }

  .dropdown-toggle::after {
    border: none;
  }

  .list-obligors{
    min-height: 300px;
    .list-group{
      max-height: 250px;
      overflow-y: auto;
    }
    .delete-select-reason{
      cursor: pointer;
      color: black;
    }
    .show-my-loans {
      font-family: Roboto-Bold;
      font-size: 11px;
    }
    .rfc{
      font-family: Roboto-Bold;
      font-size: 11px;
      text-align: center;
    }
    .name{
      font-family: Roboto-Regular;
      font-size: 12px;
      border-left: 1px solid #c0bfc0;
      text-align: left;

    }

  }
  .btns-add-obligor{
    padding-top: 10px;
  }
  .delete-select-reason{
    cursor: pointer;
    color: black;
  }
  .choices-principals{
    cursor: pointer;
    .btn-outline-primary{
      font-family: Roboto-Regular;
      margin-right: 4px;
      margin-bottom: 3px;

      .delete-select-reason{
        color: #007bff;
        font-size: 16px;

      }

    }
    .btn-outline-primary:hover{
      .delete-select-reason{
        color: white;
      }
    }
  }
  .filters-principals{
    font-family: Roboto-Regular;
    margin-top: 10px;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
  }
  .clean-filters{
    font-family: Roboto-Regular;
    margin-top: 18px;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    margin-left: 15px;
  }
  .warranty-name{
    max-width: 25px!important;
  }
  @media only screen and (min-width: 768px) {
    .desc-rel{
      max-width: 200px;
    }
  }
}
