#principal-statistics{
  padding-top: 35px;
  #garantias-totales{
    padding-bottom: 10px;
  }

  #progress-fianzas-fiado{
    background-color: #FFD000!important;
  }
  #progress-fianzas-os{
    background-color: #28A3AF!important;
  }
  #squareFiado {
    color: #FFD000;
  }
  #squareOs {
    color: #28A3AF;
  }
  #squareDisponibles {
    color: rgb(234,236,239	);
  }

  .counters{
    font-family: Roboto-Regular, Arial, serif;
    span{
      font-family: Roboto-Ligth, Arial, serif;
    }
    .fecha-cal{
      font-size: 12px;
    }
  }
  .cumulo, .disponible{
    b{
      font-family: Roboto-Medium;
    }
  }
  @media (max-width: 768px) {
    .cumulo, .disponible{
      text-align: center;
    }

  }
  @media (min-width: 768px) {
    .cumulo{
      text-align: left;
    }
    .disponible{
      text-align: right;
    }
  }
}