@import "../../../assets/css/colors";
#home{
  background-color: white;
  padding-top: 0%;
  .banner-home1{
    background-image: url('../../../assets/images/public/home/Section_front_Hero_6_V22.png');
    height: 100%;
    position: absolute;
    position: relative;
    height: 500px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 9rem;
    padding-bottom: 8rem;

    h1{
      font-family: Roboto-Regular, serif;
    }
    .card{
      font-family: Roboto-Ligth, serif;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
  .banner-home2{
    background-image: url('../../../assets/images/public/home/img-header-liberty-fianzas-006.22124edd.jpeg');
    height: 100%;
    position: absolute;
    position: relative;
    height: 500px;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 8rem;
    padding-right: 12%;
    padding-left: 12%;

    h3{
      font-family: Roboto-Medium,Arial, Serif;
      font-size: 1.75rem;
      font-weight: 700;
      color: #1a1446;
      text-align: left;
    }

    .card{
      font-family: Roboto-Ligth, serif;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border-top: 8px solid $colorPrimaryYellow;
      padding-bottom: 35px;
      padding-top: 15px;
    }
  }
  @media only screen and (max-width: 576px) {

    .banner-home1{
      height: 400px;
      padding-top: 4rem;
    }

  }
  @media only screen  and (max-width: 335px) {

    .banner-home1{
      height: 470px;
      padding-top: 4rem;
    }
    .card-home{
      margin-top: 5%;
    }
    .bottom-card-home
    {

      h5{
        margin-top: 6px;
        text-align: center!important;
      }
    }
  }

  @media only screen and (min-width: 335px) and (max-width: 576px) {

    .banner-home1{
      height: 430px;
      padding-top: 4rem;
    }

    .bottom-card-home
    {
      h5{
        margin-top: 9px;
        text-align: center!important;
      }
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {

  .banner-home1{
    height: 440px;
    padding-top: 4rem;
  }


    .bottom-card-home
    {
      h5{
        margin-top: 9px;

      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {

    .content, .banner-home1{
      //     background: palegoldenrod!important;
      padding-left: 18%;
      padding-right: 18%;
    }

  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .content, .banner-home1{
      //    background: #ffe8a1!important;
      padding-left: 18%;
      padding-right: 18%;
    }

  }

  @media only screen and (min-width: 1200px) {
    .content, .banner-home1{
      //   background: #abdde5!important;
      padding-left: 20%;
      padding-right: 20%;
    }



  }
  .header-seccion{
    padding-top: 40px;
    margin-bottom: 60px;

    h2{
      font-family: Roboto-Ligth,Arial, Serif;
      text-align: center;
      margin-bottom: 32px;
    }
    li, p{
      font-family: Roboto-Regular,Arial, Serif;
      font-size: 14px;
    }
    .fuente{
      font-family: Roboto-Regular,Arial, Serif;
      font-style: italic;
      font-size: 14px;
    }
  }
  .items{
    padding-bottom: 25px;
    .col-md-2{
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 15px;


    }
    .lm-LogoLibertyMutual{
      padding-top: 15px;
      max-width: 200px;
      max-height: 115px;
    }
    .ico{
      padding-bottom: 15px;
      height: 115px;
    }
    .lmig-Icon--color-informative path, .lm-Icon--color-informative path{
      fill: $colorPrimaryBlue!important;
    }
    h5{

      font-size: 15px;
      font-family: Roboto-Medium,Arial, Serif;
      text-align: center;
      color: $colorPrimaryBlue;

    }
  }
  .learn-more{
    padding-top: 22px;
  }
  .card-home{
    font-family: Roboto-Ligth, serif;
    margin-left: 12%;
    margin-right: 12%;

    #close-card{
      position: absolute;
      right: 45px;
      top: 20px;
      cursor: pointer;
    }
  }

  .bottom-card-home{
    margin-top: 40px;
    background-color: #f5f5f5;
    padding-top: 55px;
    h2{
      font-family: Roboto-Ligth,Arial, Serif;

    }
    .text-bottom{
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
      font-family: Roboto-Regular,Arial, Serif;

      font-size: 1rem;
      h3{
        font-family: Roboto-Medium,Arial, Serif;
        font-size: 1.75rem;
        font-weight: 700;
        color: #1a1446;
        text-align: left;
      }
    }
    img{
      padding-top: 0px;
      max-height: 400px;
    }
  }

}


