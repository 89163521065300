#statistics-warranties{
  .desc-gar{
    max-width: 140px;
  }
  .fiado{
    max-width: 150px;
  }

  .desc-gar{
    max-width: 140px;
  }
  .vigency-gar{
    min-width: 120px;
  }
  .capture-gar{
    min-width: 115px;
  }
}

