@font-face {
  font-family: Roboto-Bold;
  src: url(../../assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Roboto-Ligth;
  src: url(../../assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: Roboto-Regular;
  src: url(../../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Roboto-Medium;
  src: url(../../assets/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}