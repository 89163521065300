
#consult-query {
  padding-left: 2%;
  padding-right: 2%;

  #header-consult {
    padding-top: 5px;
    width: 100%;
    .container {
      width: 100%;
      #pipeline-2 {
        padding-left: 35%;
      }
    }

    #formulario-consult {
      padding-left: 0%;
      padding-right: 0%;
      width: 100%;

      #form-consult {
        padding-top: 3%;
        width: 100%;
      }
    }

    #guadarfecha{
      margin-left: 25%;
    }

    #cards-idcartas {
      align-items: center !important;
      align-content: center !important ;
      height: 250px;
      cursor: pointer;
      padding: 0px 0;
      box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.8);
      border: none;
      border-radius: 10px;

      #alert_up {
        padding-top: 3%;
        padding-left: 25%;
      }

      h3 {
        align-content: center;
        align-items: center;
      }

      .global-class-name {
        color: red;
      }
      .btn-archivo {
        margin-left: 165px;
      }

      p {
        color: rgb(149, 149, 149);
      }

      a {
        color: rgb(149, 149, 149);
      }

      #file-upload-input {
        position: absolute;
        display: flex;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        cursor: pointer;
      }
      #image-upload-wrap {
        position: absolute;
        align-content: center !important;
        align-items: center !important;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }
      #image-upload-wrap:hover {
        background-color: transparent;
        border: 4px dashed transparent;
      }
      #text-information {
        margin-top: 30px;
        text-align: center;
      }
    }

    #tabla {
      padding-left: 0%;
      padding-right: 5%;
    }
    #calif {
      color: rgb(196, 12, 27);
      
    }

    .lm-Modal .lm-Modal-wrapper .lm-Modal-title {
      margin: 0;
      text-align: R;
      color: #343741;
    }

    input[type="date"]:required:invalid::-webkit-datetime-edit {
      color: transparent;
    }
    input[type="date"]:focus::-webkit-datetime-edit {
      color: black !important;
    }

    #image-upload-wrap {
      position: absolute;
      align-content: center !important;
      align-items: center !important;
      height: 100%;
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }
    #image-upload-wrap {
      background-color: transparent;
      border: 4px dashed transparent;
    }
    .Modal-pdf {
      width: 100%;
      height: 100%;
      border: none;
      .iframe-pdf {
        display: block;
        /* iframes are inline by default */
        background: #000;
        border: none;
        /* Reset default border */

        width: 98%;
        height: 100vh;
        /* Viewport-relative units 600px*/

        padding-left: 2%;
        padding-right: 2%;
      }

      #btn-fiado-busqueda {
        margin-left: 70%;
        margin-top: 7%;
      }
    }

    .lm-Modal .lm-Modal-wrapper .lm-Modal-title {
      margin: 0;
      text-align: R;
      color: #343741;
    }

    .Modal-fecha {
      width: 100%;
      height: 100%;
      border: none;
      .iframe-pdf {
        display: block;
        /* iframes are inline by default */
        background: #000;
        border: none;
        /* Reset default border */
        width: 98%;
        height: 100vh;
        /* Viewport-relative units 600px*/
        padding-left: 2%;
        padding-right: 2%;
      }

      #btn-fecha-auto {
        margin-left: 50%;
        margin-top: 7%;
      }
    }

    .Modal-fiados {
      width: 80%;
      height: 80%;
      padding-left: 15%;
      padding-top: 5%;

      .iconModal {
        margin-left: 1000px;
      }

      #btn-fiado-busqueda {
        margin-left: 70%;
        margin-top: 7%;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    #form-consult {
      padding-left: 4%;
      padding-right: 4%;
    }
  }
  @media (min-width: 768px) and (max-width: 1100px) {
    .lm-Table-wrapper {
      overflow-x: scroll;
    }
  }
  @media (min-width: 1100px) {
    .lm-Table-wrapper {
      overflow-x: inherit;
    }
  }
  .lm-Modal.lm-Modal--large .lm-Modal-wrapper {
    max-width: 1200px;
    overflow: hidden;
  }

  .autocomplete-wrapper-fecha {
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .autocomplete-wrapper-fecha > div {
    width: 100%;
    cursor: pointer;
  }

  .autocomplete-wrapper-fecha input {
    border-radius: 3px;
    border: 1px solid #919191;
    padding: 12px 15px;
    font-size: 18px;
    width: 100%;
    cursor: pointer;
    padding: 1em 0.375em 0.8125em;
  }

  .autocomplete-wrapper-fecha input:hover {
    border: 1px solid #28a3af;
    padding: 12px 15px;
    font-size: 18px;
    width: 100%;
    cursor: pointer;
    border-radius: 3px;
    padding: 1em 0.375em 0.8125em;
  }

  .autocomplete-wrapper-fecha input:focus {
    border-color: #28a3af;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
  }

  .autocomplete-wrapper-fecha .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 280px;
    overflow: hidden;
    background-color: #ffffff;
    border: none;
    border-top: none;
    cursor: pointer;
    border-radius: 3px;
    position: relative;
    z-index: 3;
    font-size: 1rem;
    -webkit-transition: opacity 0.1s ease-out 0s;
    transition: opacity 0.1s ease-out 0s;
    border-radius: 3px;
    text-overflow: ellipsis;
    outline: none;
  }
}

