@import "../../../assets/css/colors";
#name-system{
  width: 110px;



  #sub-header{
    font-family: Roboto-Regular, Arial;
    color: $colorPrimaryDark;
    font-size: 16px;
    display: inline!important;


    //border-top: 2.5px solid $colorPrimaryDark;
    text-align: right;
  }

}
