@import "../../../assets/css/colors";



.status-expedient{
  margin-top: 5px;
}

.open-expedient{
  cursor: pointer;
}
.expedient-warranty{
  .card-header{
    background-color: $colorPrimaryYellow!important;
    color: $primaryText;
    font-family: Roboto-Bold,Arial;
    font-size: 14pt;
  }
  .card{
    margin-top: 20px;
  }
  .list-group-item{
    cursor:move;
  }
}
.actions-expedient{
  .btn{
    background-color: transparent;
    border-color: transparent;
  }
}
.drop-area{
  background-color: #F5F5F5;
}
@media only screen and (max-width: 720px) {
  .actions-expedient{
    margin-top: 18px;
    background-color: #F5F5F5;
  }

}

.modal1{
  max-width: 800px;
  color: #919191;
  position: fixed;
  margin:  auto;
  

 h3{
  font-size: medium;
 }  

 } 

 .modalF{
  max-width: 800px;
  color: #919191;
  position: fixed;
  margin:  auto;

 h3{
  font-size: medium;
 }  

 }
 
 #modal3 {
  max-width: 800px;
  color: #343741 !important;
  position: fixed;
  margin: auto;

  h3 {
    font-size: large;
    color: #343741 !important;
  }

  h2 {
    font-size: large;
    color: #343741 !important;
  }

  .info {
    margin-left: 35%;
  }

  .texto {
    color: #343741 !important;
    font-size: large;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 10%;
    margin-bottom: 6%;

  }

  .btn-cancel {
    margin-left: 33%;
  }

}

.select-fiados{ 
 .dropdown{

  padding: 1px 1px 1px 1px;
  left: 1px;
  font-size: 16px;
  text-align: left;
  -webkit-transition: opacity .1s ease-out 0s;
  transition: opacity .1s ease-out 0s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 3px;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: 1px solid #919191;
  background-color: #fff ;
  color: #565656!important;  
 

} 


.dropdown:hover{

  padding: 1px 1px 1px 1px;
  left: 1px;
  text-align: left;
  font-size: 16px;
  -webkit-transition: opacity .1s ease-out 0s;
  transition: opacity .1s ease-out 0s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 3px;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: 1px solid #919191;
  background-color: #fff ;
  color: #565656!important;  
 

} 


.title{

  color: #565656!important;  
  margin-top: 20px;
} 

.selected-item{

  color: black!important;  
  margin-top: 20px;
  background-color: #28a3af;
  background-size: 150px;
  background-position: right;
} 


input {

  padding: 18px 507px 18px 10px;
  text-align: center;
  font-size: 16px;
  font-display: initial;
  -webkit-transition: opacity .1s ease-out 0s;
  transition: opacity .1s ease-out 0s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 3px;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  outline: none;
  border: 1px solid #919191;
  background-color: #fff ;
  color: black!important;

} 

input:hover {

 padding: 18px 507px 18px 10px;
 text-align: center;
 font-size: 16px;
 -webkit-transition: opacity .1s ease-out 0s;
 transition: opacity .1s ease-out 0s;
 -webkit-transform: translateZ(0);
 transform: translateZ(0);
 border-radius: 3px;
 text-overflow: ellipsis;
 width: 100%;
 cursor: pointer;
 outline: none;
 border: 1px solid #28a3af;
 background-color: #fff;
 color: #343741!important;

} 


.btn-aceptar{

  margin-top: 15px;
  margin-left: 570px;
} 
}


  
.autocomplete-wrapper-cliente {
  width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;
 
  
}

.autocomplete-wrapper-cliente > div {
  width: 100%;
  cursor: pointer;
 
}

.autocomplete-wrapper-cliente input {
  border-radius: 3px;
  border: 1px solid  #919191;
  padding: 12px 15px;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  padding: 1em .375em .8125em;
}


.autocomplete-wrapper-cliente input:hover {
  border: 1px solid #28a3af;
  padding: 12px 15px;
  font-size: 18px;
  width: 100%;
  cursor: pointer;
  border-radius: 3px;
  padding: 1em .375em .8125em;
 
}

.autocomplete-wrapper-cliente input:focus {
  border-color: #28a3af;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
}

.autocomplete-wrapper-cliente .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid  #28a3af;
  border-top: none;
  cursor: pointer;
  border-radius: 3px;
}

.autocomplete-wrapper-cliente .item  {
  display: block;
  cursor: pointer;
  font-size: 18px;
  padding: 1px;
  cursor: pointer;
  
}

.autocomplete-wrapper-cliente .item.selected-item {
  background-color: #28a3af;
  color: #FAFBFC;
  cursor: pointer;
}

.autocomplete-wrapper-cliente .item:hover {
  background-color:  #28a3af;
  color: #FAFBFC;
  cursor: pointer;
}

