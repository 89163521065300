#documents-loans{
  height: 100vh;
  iframe {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    border: none;
  }
}

