#principal-actions{
  padding-top: 15px;
  #docu-ware{
    cursor: pointer;
    img{
      max-width: 170px;
    }
  }
    margin-top: 3px;
    $circle:55px;
  .badge-pill{
      width: $circle;
      height: $circle;
      padding: 6px;
    }

    $width: 45px;
  .lock-principal{
    text-decoration: none!important;
    cursor: pointer;
    font-family: Roboto-Medium;
    font-size: 20px;
    color: rgb(53,53,53);
    span{
      margin-left: 2px;
   //   margin-top: 100px!important;
    }
    .col-9{
      padding-top: 5px!important;
    }
    img{
      min-width: $width;
    }
  }
  .unlock-principal{
    text-decoration: none!important;
    cursor: pointer;
    font-family: Roboto-Medium;
    font-size: 20px;
    color: rgb(53,53,53);
    span{
      margin-left: 2px;
   //   margin-top: 100px!important;
    }
    .col-9{
      padding-top: 5px!important;
    }
    img{
      min-width: $width;
    }
  }

  @media only screen and (max-width: 768px) {
    .lock-principal{
      img{
        max-width: 10px;
      }
    }
    .unlock-principal{
      img{
        max-width: 10px;
      }
    }
  }


}
