@import "../../../assets/css/colors";
#spinner-loading{
  text-align: center;
  .spinner-border{
    width: 3rem!important;
    height: 3rem!important;
  }
  .text-warning {
    color: $colorAcent;
  }
}