#comentarios-fiado{
  //max-height: 2020px;
  overflow-y: auto;
  .card{
    background: none;
    border: none;
  }
  .card-header{
    //text-align: right;
    cursor: pointer;
    border: none;
    background: none;
    //color: #06748C;
  }
  .card-body{
    .escribe-comentario{
      margin-bottom: 15px;
    }


    .message-blue {
      position: relative;
      margin-left: 5px;
      //margin-bottom: 10px;
      padding: 10px;
      background-color: #E6E6E6;

      text-align: left;
      font-family: Roboto-Regular;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
    }

    .message-orange {
      position: relative;
      //margin-bottom: 10px;
      margin-right: 5px;
      padding: 10px;
      background-color: #99E5EA;
      color: #1A1446;
      text-align: left;
      font-family: Roboto-Regular;
      border: 1px solid #99E5EA;
      border-radius: 10px;
    }

    .message-content {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0px;
      margin: 0;
      font-size: .70em;
    }

    .message-timestamp-right {
      margin-right: 35px ;
      text-align: right;
      font-size: .70em;
      font-weight: 300;
      //bottom: 5px;
      right: 5px;
      margin-bottom: 10px;
    }

    .message-timestamp-left {
      margin-left: 35px ;
      text-align: left;
      font-size: .70em;
      font-weight: 300;
      //bottom: 5px;
      left: 5px;
      margin-bottom: 10px;
    }

    .message-blue:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 15px solid #E6E6E6;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      top: 0;
      left: -15px;
    }

    .message-blue:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 17px solid #E6E6E6;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      top: -1px;
      left: -17px;
    }

    .message-orange:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 15px solid #99E5EA;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      bottom: 0;
      right: -15px;
    }

    .message-orange:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 17px solid #99E5EA;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      bottom: -1px;
      right: -17px;
    }



  }
  @media only screen and (min-width: 768px) {
    .card-body{
      //     padding-left: 45%;
    }

  }

  .btn-send {
    position: relative;
    text-align: right;
  }

  .message-user{
    font-weight: bold;
    font-size: .75em;
  }
  .scroll_div{
    overflow-y: auto;
    max-height: 150px;

  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .card-body {
    padding: .80em !important;
  }
  .card-header {
    padding: .80em !important;
  }
}