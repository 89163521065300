#reporte-garantias{
  .reporte-garantia-container{
    padding-left: 8%;
    padding-right: 8%;
  }

  
  thead {
    min-width: 100px;
    height: 40px;
    background-color: rgb(162, 161, 157);
  }

  .contenedor {
    width: 100%;
    display: inline-flex;
    align-items: center;
  }

  hr {
    border: 0;
   
    border-top: 15px solid #e9ecef; 
    background-color: #e9ecef;
    border-bottom: 15px solid #e9ecef;
    height:0;
    }


    div.container { max-width: 1200px }

  input {
      width: 250px;
      padding: 13px;
  }

  .ex2{ overflow-x:hidden;}

  
  
  autocomplete-wrapper-agent{
    overflow-x: hidden;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    border: solid grey 1px;
    display: none;
  }

  .titulo-reportes{
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
      font-family: Roboto-Regular, Arial, serif;
    }
    h4{
      background: #dbdbe2;
      color: black; 
    }
  }

  
}