#modal-lock-principal{
  .list-group-item{
    cursor: pointer!important;
  }
  .list-reject{
    //min-height: 355px;
  }
  .add-description{
    margin-top: 5px;
    margin-bottom: 4px;
    font-size: 15px;
    color: #707070;
  }
}