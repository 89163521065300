#confirmacion-baja {
  p {
    color: rebeccapurple;
  }

  .contenedor {
        height: 100vh;  

  }

  .container-center {
    position: absolute;
    top:50%;
    left: 45%;           
    margin-top: -100px;
    margin-left: -100px;
  }
  .center {
    margin-top: 20%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }

}