#select-language{
  .flags{
    padding: 9%;
    box-sizing: border-box;
    display: inline-block!important;
    position: relative!important;
    text-align: center!important;
    vertical-align: middle;
    max-width: 64px;
    cursor: pointer;
    .img-circle{
      border-radius: 50%;


      border: 2.5px solid white!important;
    }
    @media only screen and (max-width: 375px) {
      .img-circle{
        height: 32px;
      }
    }
    @media only screen and (min-width: 375px) and  (max-width: 768px){
      .img-circle{
        height: 32px;
      }
    }
    @media only screen and (min-width: 768px) and  (max-width: 1280px){
      .img-circle{
        height: 32px;
      }
    }
    @media only screen and (min-width: 1280px) {
      .img-circle{
        height: 48px;
      }
    }
  }

  // modal

  .modal-language{
    padding-bottom: 45px;
    .form-check-input{

    }
    .form-check-label{
      font-family: Roboto-Regular;

      padding-bottom: 5px;
      img{
        height: 20px;
        margin-right: 5px;
        margin-left: 5px;

      }
    }
  }
}