@import "../../../assets/css/colors";


.select-blocks{


    


    //Para multiselect
    .css-1s2u09g-control{

        padding: 1px 1px 12px 1px;
        left: 1px;
        font-size: 17px;
        text-align: left;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: black!important;
      
    } 


    .css-1s2u09g-control:hover{

        padding: 1px 1px 12px 1px;
        left: 1px;
        font-size:  17px;
        text-align: left;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #28a3af;
        background-color: #fff;
        color: #343741!important;
    } 

    

    .css-1pahdxg-control{
       
        padding: 1px 1px 12px 1px;
        left: 1px;
        font-size:  .8rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #28a3af;
        background-color: #fff;
        color: #343741!important;

    } 


    .css-1pahdxg-control:hover{
       
        padding: 1px 1px 12px 1px;
        left: 1px;
        font-size: .8rem;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #28a3af;
        background-color: #fff;
        color: #343741!important;

    } 


    .css-26l3qy-menu:hover{
        padding: 1em .375em .8125em;
        left: 1px;
        font-size: 16px;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
         border: 1px solid #919191;
         background-color: #fff;
        color: #565656!important;

    } 

   
    .css-26l3qy-menu{
        padding: 1em .375em .8125em;
        left: 1px;
        font-size: 16px;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff;
        color: #565656!important;  

    } 


    .css-1okebmr-indicatorSeparator{

        width: 0px;
    } 


    .css-12jo7m5{
        color: #565656!important;  
        padding: 5px 10px 5px 10px;
        font-size: 14px;
        //background-color:#fff!important;  
    } 


     input {

         padding: 13px 400px 13px 1px;
         text-align: left;
         font-size: 16px;
         font-display: initial;
         -webkit-transition: opacity .1s ease-out 0s;
         transition: opacity .1s ease-out 0s;
         -webkit-transform: translateZ(0);
         transform: translateZ(0);
         border-radius: 3px;
         //text-overflow: ellipsis;
         width: 200%;
         cursor: pointer;
         outline: none;
         border: 1px solid #fff ;
         background-color: #fff ;
         color: black!important;
    
    }  
    
    /* input:hover {
    
        padding: 13px 5px 13px 2px;
        text-align: left;
        font-size: 16px;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #28a3af;
        background-color: #fff;
        color: #343741!important;
    
    }  */


    
    .dropdown{
    
        padding: 1px 1px 1px 1px;
        left: 1px;
        font-size: 16px;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #fff ;
        background-color: #fff ;
        color: #565656!important;  
       
       
    
    } 
    
    
    /* .dropdown:hover{
    
        padding: 1px 1px 1px 1px;
        left: 1px;
        font-size: 16px;
        -webkit-transition: opacity .1s ease-out 0s;
        transition: opacity .1s ease-out 0s;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        border-radius: 3px;
        text-overflow: ellipsis;
        width: 100%;
        cursor: pointer;
        outline: none;
        border: 1px solid #919191;
        background-color: #fff ;
        color: #565656!important;  
       
    
    }  */
    
    
    .title{
    
        color: #565656!important;  
        margin-top: 12px;
    } 
    
    .selected-item{
    
        color: black!important;  
        margin-top: 12px;
        background-color: #28a3af;
        background-size: 90px;
        background-position: right;
    } 




} 




 //Para el monto
.currency-input{
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #919191;
    background-color: #fff;
    color: #343741!important;
} 


.currency-input:hover{
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #28a3af;
    background-color: #fff;
    color: #343741!important;
} 


//Para el producto
.producto{
    padding: 1px 1px 1px 20px;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #919191;
    background-color: #fff;
    color: #343741!important;
} 


 //Para el motivo
 .motivo-input{
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #919191;
    background-color: #fff;
    color: #343741!important;
} 

.motivo-input:hover{
    padding: 1em .375em .8125em;
    left: 1px;
    font-size: 1rem;
    -webkit-transition: opacity .1s ease-out 0s;
    transition: opacity .1s ease-out 0s;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    border-radius: 3px;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: 1px solid #28a3af;
    background-color: #fff;
    color: #343741!important;
} 


  
  .autocomplete-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
   
    
  }
  
  .autocomplete-wrapper > div {
    width: 100%;
    cursor: pointer;
   
  }
  
  .autocomplete-wrapper input {
    border-radius: 3px;
    border: 1px solid  #919191;
    padding: 12px 15px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    padding: 1em .375em .8125em;
  }
  

  .autocomplete-wrapper input:hover {
    border: 1px solid #28a3af;
    padding: 12px 15px;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    border-radius: 3px;
    padding: 1em .375em .8125em;
   
  }

  .autocomplete-wrapper input:focus {
    border-color: #28a3af;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .autocomplete-wrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid  #28a3af;
    border-top: none;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .autocomplete-wrapper .item  {
    display: block;
    cursor: pointer;
    font-size: 14px;
    padding: 1px;
    cursor: pointer;
    
  }
  
  .autocomplete-wrapper .item.selected-item {
    background-color: #28a3af;
    color: #FAFBFC;
    cursor: pointer;
  }
  
  .autocomplete-wrapper .item:hover {
    background-color:  #28a3af;
    color: #FAFBFC;
    cursor: pointer;
  }
