@import "./assets/css/colors";
// botones de liberty
$colorSuccess : #008040;
$colorAutorizar: #1A1446;
$colorVencido: #D32F2F;
$colorRechazado: #0061F5;
$colorPAF: #1A1446;
$colorRPF: #1A1446;
$width-btns: 26px;


@media only screen and  (max-width: 576px) {
  .container-fluid{
    padding-top: 20%;
  }

}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .container-fluid{
    padding-top: 12%;
  }

}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .container-fluid{
    padding-top: 15%;
  }

}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .container-fluid{
    padding-top: 7%;
  }

}

@media only screen and (min-width: 1200px) {
  .container-fluid{
    padding-top: 7%;
  }

}

.text-success{
  color: $colorSuccess!important;
  font-family: Roboto-Regular, Arial, serif;
}
.btn-success{
  background-color: $colorSuccess!important;
  border-color: $colorSuccess!important;
  font-family: Roboto-Regular, Arial, serif;
}
.title-seccion{
  font-family: Roboto-Regular, Arial, serif;
}
// container padding
.waranty-container {
  padding-left: 8%;
  padding-right: 8%;
}

// botonera
.seccion-botons{
  margin-top: 20px;

}

// ajustes
.lm-FieldGroup-field--checkbox .lm-FieldOption-input[type=checkbox]+.lm-FieldOption-tick:before{
  z-index: 0;
}

.input-sdgf {
  padding: 1em .375em .8125em;
  left: 1px;
  font-size: 1rem;
  -webkit-transition: opacity .1s ease-out 0s;
  transition: opacity .1s ease-out 0s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 3px;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  outline: none;

  border: 1px solid #919191;
  background-color: #fff;
  color: #343741!important;
}
input:disabled {
  background-color: #e6e6e6;

}

// radio-liberty

// paginacion de las data tables
.pagination-sdgf{
  font-family: Roboto-Regular;
  padding-bottom: 8px;
  padding-top: 15px;

  .show-registers{
    padding-top: 12px;
  }
  .page-link {
    color: $colorPrimaryDark;
  }
  .page-item.active .page-link{
    color: white!important;
  }
  .btn-group-vertical>.btn, .btn-group>.btn {
    width: 150px;
  }
}





.btn-autorizar,  .btn-autorizar:hover {

  background-color: white!important;
  border-color: $colorAutorizar!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: $colorAutorizar!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;

}



.btn-cumplido, .btn-cumplido:hover {
  background-color: white!important;
  border-color:  $colorSuccess!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  color:  $colorSuccess!important;
  text-align: center;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}



.btn-vencido, .btn-vencido:hover{
  background-color: white!important;
  border-color: #D32F2F!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#D32F2F!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;

}

.btn-revocado, .btn-revocado:hover{
  background-color: white!important;
  border-color: #D32F2F!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#D32F2F!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}

.btn-rechazado, .btn-rechazado:hover{
  background-color: white!important;
  border-color: #0061F5!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#0061F5!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}

.btn-paf, .btn-paf:hover {

  background-color: white!important;
  border-color: #1A1446!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#1A1446!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}

.btn-rpf, .btn-rpf:hover{

  background-color: white!important;
  border-color: #1A1446!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#1A1446!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}



.btn-informacion{
  background-color: white!important;
  border-color: #283593!important;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#283593!important;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}

.btn-en-espera{
  background-color: white;
  border-color: #FFD000;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#FFD000;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}

.btn-sustituida{
  background-color: white;
  border-color: #ff8f00;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#ff8f00;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}

.btn-baja{
  background-color: white;
  border-color: #757575;
  border-width: 2px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color:#757575;
  max-width: 200px;
  min-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
  font-family: Roboto-Bold;
}


.textContact{

  margin-top: 1%;
  margin-left: 50%;
}

.email{
  margin-left: 45%;
}

#form--codigoconfirmacion {

  margin-top: 3%;
  text-align: left;
  
}

#fieldgroup--codigoconfirmacion{

 margin: 5%;
}



.lmig-Button.lmig-Button--secondary {

  border-color: none!important; 
  color:none!important; 

}

@media (min-width: 768px) and  (max-width: 1100px) {
  .lm-Table-wrapper{
    overflow-x: scroll;
  }
}
@media (min-width: 1100px) {
  .lm-Table-wrapper{
    overflow-x: auto;
  }
}

.lm-Modal.is-open{
  z-index: 1003!important;
}

