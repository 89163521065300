@import "../../../assets/css/colors";
#menu{

  .card-menu{
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-top: 35px;
    h3{
      margin-bottom: 9px;
      font-family: Roboto-Regular, serif;
    }
  }
  #modules{
    padding-left: 14px;
    padding-right: 14px;
    .col-md-6{
      padding-left: 4px;
      padding-right: 4px;
    }
    .card{
      box-shadow: none!important;
      padding-top: 6px;
      padding-bottom: 6px;
      cursor: pointer;
      .badge{
        position: absolute;
        right: 18px;
        top: 10px;
        font-family: Roboto-Regular, serif;
        font-size: 8px;
        color: white;
      }
      .col-4{
        img{
          margin-top: 10px;
          height: 52px;
        }
        padding-left: 5px;
      }
      .warranties{
        border-right: 5px solid #fcb028;
      }
      .engagments{
        border-right: 5px solid #821c80;
      }
      .credit-bureu{
        border-right: 5px solid #86c4ce;
      }
      .human-resources{
        border-right: 5px solid #89c732;
      }
      .accounting{
        border-right: 5px solid #F25910;
      }
    }

    .title-module{
      font-family: Roboto-Regular;
      color: $colorPrimaryDark;
      text-align: left;
      font-size: 19px;
      padding-top: 5px;
    }
    .see-module{
      font-family: Roboto-Ligth, Arial, Serif;
      font-size: 12px;
    }
  }
  .msg-close-session{
    font-family: Roboto-Regular;
    margin-top: 10px;
  }
}