#creditbureau {
    padding-left: 8%;
    padding-right: 8%;

    .cards-percentage {
        .card-title {
            font-family: Roboto-Regular, serif;
            border-bottom: 4px solid #1a1446;
            padding-bottom: 15px;
            display: flex;
        }

        #progressbar {
            align-items: center;
            align-content: center;
        }
    }

    #search {
      
        align-items: center;
        align-content: flex-start !important;

        padding: 3px;
        width: 50%;
        height: 40px;

       
    }

    #table-credit {
        .tb {
            width: 250px;
            align-items: center;
            position: relative;
            justify-content: center !important;
        }
    }
}
