@import "../../../assets/css/colors";
#nav-bar-login{
  .navbar {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: $colorPrimaryYellow;
  }
  .navbar-light .navbar-toggler{
    border-color:transparent!important;
  }

  @media screen and (max-width: 576px) {
    .content-nav-bar{

    }
  }
  @media screen and (min-width: 576px) and  (max-width:768px){
    .content-nav-bar{

    }
    #select-language-sm{
      display: none;
    }
  }
  @media screen and (min-width: 768px) and  (max-width:992px){
    .content-nav-bar{
      padding-left: 18%;
      padding-right: 18%;
    }
    #select-language-sm{
      display: none;
    }
  }
  @media screen and (min-width: 992px) and  (max-width:1200px){
    .content-nav-bar{
      padding-left: 18%;
      padding-right: 18%;
    }
    #select-language-sm{
      display: none;
    }
  }
  @media screen and (min-width: 1200px) {
    .content-nav-bar{
   //   background: #abdde5!important;
      padding-left: 20%;
      padding-right: 20%;
    }
    #select-language-sm{
      display: none;
    }

  }

  .logo-liberty{
    cursor: pointer;
  }

  .link-nav{
    font-family: Roboto-Regular, Arial;
    color: $colorPrimaryDark;
   // text-transform: uppercase;
    font-size: 16px;
    padding-right: 25px;
  }
  .link-nav:hover{
    color: $colorPrimaryBlue;
    text-decoration: underline;
  }

  .logo-liberty-nav{
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-right: 30px;
    img{
      width: 161.28px;
      height: 76.32px;
    }
  }
  .navbar-text{
    padding-right: 20px;
  }
  #select-language-sm{
    position: absolute;
    right: 85px;
    top: 20px;
  }
}

