#review-docs{
  @import "../../../assets/css/colors";
  #nav-bar{
    #video{

      padding-left: 5%;
      padding-right: 5%;
      margin-bottom: 30px;
    }

    .navbar {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      background-color: $colorPrimaryYellow;
      font-family: Roboto-Regular;
      a{
        color: #1a1446!important;
      }

    }
    .fixed-top{
      z-index: 9!important;
    }
    .ico-liberty{
      cursor: pointer;
    }
    #toogle-ico {
      padding-top: 15px;
      margin-right: 15px;
    }
    .lm-Icon{
      margin-right: 5px;
      color: white;
    }
    .circleBase {
      border-radius: 50%;
      // behavior: url(PIE.htc); /* remove if you don't care about IE8 */
    }
    .icons-nav{
      .bg-white{
        border-radius: 50%;
        padding: 3px;
        cursor: pointer;
      }
      .lm-Icon{
        padding: 3px;
      }
    }

    .menu-toogle{
      width: 45px;

      color: $primaryText!important;
      font-size: 21px;
    }


    .side-nav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 101;
      top: 0;
      left: 0;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 20px;
      margin-top: 79px;

    }
    .bg-light {
      background-color: #1A1446!important;
    }
    .background-nav {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      overflow-x: hidden;
      transition: 0s;
      padding-top: 60px;
      margin-top: 79px;
      cursor: pointer;
    }
    .side-nav a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      color: #f1f1f1;
      font-family: Roboto-Regular;
      display: block;
      transition: 0.3s;
      cursor: pointer;
    }

    .side-nav a:hover {

      color: #818181;
      cursor: pointer;
    }

    .side-nav .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      margin-left: 50px;
    }

    @media screen and (max-height: 450px) {
      .side-nav {padding-top: 15px;}
      .side-nav a {font-size: 18px;}
    }

    @media screen and (max-width: 991px) {
      .menu-toogle{
        //display: none;
      }
    }

    .select-language{
      padding-right: 20px!important;
      .lm-FieldGroup-field--select .lm-Field-select{
        font-size: 12px;
        height: 3rem;
        margin-right: 21px;
      }
    }


    .accordion{
      .card {
        background-color: transparent;
        border: none;
        .card-header{
          cursor: pointer;
          font-family: Roboto-Medium, serif;
          font-size: 17.5px!important;
          color: $colorPrimaryYellow;
        }
        .card-header:hover {
          background-color: $colorAcent;
          color: white;
        }
        .card-body {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
        ul{
          cursor: pointer;
          list-style:none;
          color: #E6E6E6;
          padding-inline-start: 10px;
          font-family: Roboto-Regular, serif;
          font-size: 15px!important;
          li{
            padding-bottom: 5px;
            padding-top: 5px;
          }
          li:hover{
            color: #FFFFFF;
          }
        }
      }
    }

  }

  //  margin: 0;            /* Reset default margin */
  iframe {
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
    height: 100vh;        /* Viewport-relative units 600px*/
    width: 98%;
    padding-left: 2%;
    padding-right: 2%;
  }
  .lm-Modal .lm-Modal-wrapper {
    overflow-y: hidden!important;
  }
  h3{
    padding-bottom: 8px;
    font-family: Roboto-Regular;
  }
  .container-center {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .fontTitle{
    color: #ffffff;
  }
  #no-documents{
    min-height: 800px!important;
    font-family: Roboto-Regular;
    color: white;

  }
}