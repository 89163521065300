#consult-query {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  
    #header-consult {
      padding-top: 20px;
      width: 100%;
      .container {
        #pipeline-2 {
          padding-left: 35%;
        }
      }
  
      #formulario-consult {
        padding-left: 0%;
        padding-right: 0;
        width: 100%;
  
        #form-consult {
          padding-top: 3%;
          width: 100%;
        }
      }
  
      #cards {
        align-items: center !important;
        align-content: center !important ;
        height: 300px;
        cursor: pointer;
        padding: 0px 0;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.8);
        border: none;
        border-radius: 10px;
  
        .global-class-name {
          color: red;
        }
  
        p {
          color: rgb(149, 149, 149);
        }
  
        a {
          color: rgb(149, 149, 149);
        }
      }
  
      #tabla {
        padding-left: 0%;
        padding-right: 0%;
        .tb {
          width: 900px;
          align-items: center;
          position: relative;
          justify-content: center !important;
          overflow-x: visible;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      #form-consult {
        padding-left: 4%;
        padding-right: 4%;
      }
    }
  
    @media (min-width: 768px) and (max-width: 1100px) {
      .lm-Table-wrapper {
        overflow-x: scroll;
      }
    }
    @media (min-width: 1100px) {
      .lm-Table-wrapper {
        overflow-x: visible;
      }
    }
  }
  