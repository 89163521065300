#create-warranty{
  #clients-exclusive{
    .wrapper{
      font-family: Roboto-Ligth;
      font-size: 1rem;
      -webkit-transition: opacity .1s ease-out 0s;
      transition: opacity .1s ease-out 0s;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      border-radius: 3px;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      outline: none;
      position: absolute;
      border: 1px solid #919191;
      background-color: #fff;
      color: #343741!important;
      z-index: 4;
    }
  }

}