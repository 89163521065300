#consult-query {
    padding-left: 2%;
    padding-right: 2%;
  
    #header-consult {
      padding-top: 5px;
      width: 100%;
      .container {
        width: 100%;
        #pipeline-2 {
          padding-left: 55%;
        }
      }
  
      #formulario-consult {
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;
  
        #form-consult {
          padding-top: 3%;
          width: 100%;
        }
      }
  
      #cards {
        align-items: center !important;
        align-content: center !important ;
        height: 300px;
        cursor: pointer;
        padding: 0px 0;
        box-shadow: 0 0 4px 0 rgba($color: #000000, $alpha: 0.8);
        border: none;
        border-radius: 10px;
  
        #alert_up {
          padding-top: 3%;
          padding-left: 25%;
        }
  
        h3 {
          align-content: center;
          align-items: center;
        }
  
        .global-class-name {
          color: red;
        }
  
        p {
          color: rgb(149, 149, 149);
        }
  
        a {
          color: rgb(149, 149, 149);
        }
  
        #file-upload-input {
          position: absolute;
          display: flex;
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          outline: none;
          opacity: 0;
          cursor: pointer;
        }
        #image-upload-wrap {
          position: absolute;
          align-content: center !important;
          align-items: center !important;
          height: 100%;
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
        }
        #image-upload-wrap:hover {
          background-color: transparent;
          border: 4px dashed transparent;
        }
        #text-information {
          margin-top: 30px;
          text-align: center;
        }
      }
  
      #tabla {
        padding-left: 0%;
        padding-right: 5%;
      }
    }
    @media only screen and (max-width: 600px) {
      #form-consult {
        padding-left: 4%;
        padding-right: 4%;
      }
    }
    @media (min-width: 768px) and (max-width: 1100px) {
      .lm-Table-wrapper {
        overflow-x: scroll;
      }
    }
    @media (min-width: 1100px) {
      .lm-Table-wrapper {
        overflow-x: visible;
      }
    }
  }
  